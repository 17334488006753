@import "~styles/variables";

.footer {
  margin-top: 200px;
  background: $black;
  padding: 80px 0;

  &_alt {
    margin-top: 80px;
  }

  &_no_margin {
    margin-top: -8px;
  }

  & .logo {
    img {
      display: block;
      margin-bottom: 24px;
      transition: opaticy 0.2s ease;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }

    span {
      display: block;
      color: #adb3bd;
    }
  }

  & .list {
    display: flex;
    justify-content: space-between;
  }

  & .item {
    strong {
      display: block;
      margin-bottom: 20px;
      font-size: 24px;
      line-height: 32px;
      color: #fff;
      font-weight: 800;
      font-family: "Raleway", sans-serif;
    }

    a {
      color: #adb3bd;
      font-size: 16px;
      transition: all 0.2s ease;
      display: block;

      &:not(:last-child) {
        margin-bottom: 20px;
      }

      &:hover {
        opacity: 0.8;
      }
    }
  }

  @media (max-width: 1200px) {
    & .list {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      padding-left: 10%;
    }
    & .item {
      padding-top: 20px;
    }
  }

  @media (max-width: 1024px) {
    margin-top: 80px;

    & .list {
      grid-gap: 0 32px;
      padding-left: 32px;
    }
  }

  @media (max-width: 767px) {
    padding-top: 40px;
    padding-bottom: 40px;

    & .list {
      grid-template-columns: 1fr;
      grid-gap: 32px;
      padding-left: 16px;
    }

    & .logo {
      margin-top: 32px;
    }

    & .item {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 8px 12px;
      white-space: normal;
      padding: 0;

      strong {
        grid-column: 1 / span 2;
        margin-bottom: 4px;
        line-height: 24px;
        font-size: 18px;
      }

      a {
        line-height: 1.6;
        font-size: 14px;

        &:not(:last-child) {
          margin-bottom: 0;
        }
      }
    }
  }
}
